import { useQuery } from '@tanstack/react-query';
import IconText from '_common/component/IconText';
import NoData from '_common/component/NoData';
import ZingIcon from '_common/component/ZingIcon';
import _ from 'lodash';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ServiceAPI from 'states/api/ServiceAPI';
import FlagVN from 'styles/images/Icons/FlagVN.svg';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  Spin,
  formatCurrency,
} from 'tera-dls';
import { SERVICE_CLOUD_VPS_URL } from '../../url';

const UpgradeVPSPage = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Cloud VPS
        </span>
      ),
      onClick: () => navigate(SERVICE_CLOUD_VPS_URL.list.path),
    },
    {
      title: 'Nâng cấp dịch vụ',
    },
  ];

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-cloud-vps-detail', id],
    () => ServiceAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  const configDetails = [
    {
      icon: <ZingIcon.IconLocation color="#3f83f8" />,
      label: 'RAM:',
      value: dataDetail?.ram,
      unit: 'GB',
    },
    {
      icon: <ZingIcon.IconSquareBox color="#3f83f8" />,
      label: 'vCPU:',
      value: dataDetail?.cpu,
      unit: '',
    },
    {
      icon: <ZingIcon.IconEarth color="#3f83f8" />,
      label: 'SSD:',
      value: dataDetail?.ssd,
      unit: 'GB',
    },
    {
      icon: <ZingIcon.IconWindows color="#3f83f8" />,
      label: 'Mbps:',
      value: '',
      unit: '',
    },
  ];

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      <Spin spinning={isLoadingDetail}>
        {isErrorDetail || _.isEmpty(dataDetail) ? (
          <NoData />
        ) : (
          <div className="px-2 flex flex-col gap-4">
            <div className="bg-white p-4 rounded-[10px] drop-shadow flex flex-col">
              <div className="flex flex-col gap-4">
                <span className="text-blue-500 font-bold">
                  Cấu hình dịch vụ hiện tại của Quý khách
                </span>
                <div className="px-3 flex flex-col gap-2">
                  <IconText prefix={<ZingIcon.IconVPS color="#0E9F6E" />}>
                    <span className="font-[500]">Cấu hình:</span>
                  </IconText>
                  <div className="grid grid-cols-2">
                    {configDetails.map((detail, index) => (
                      <IconText
                        key={index}
                        prefix={detail.icon}
                        className="py-4 border-b-[1px]"
                      >
                        <span>{detail.label}</span>
                        <span className="font-[500] text-green-500">
                          {detail.value}
                        </span>
                        <span>{detail.unit}</span>
                      </IconText>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-4 rounded-[10px] drop-shadow flex flex-col gap-4">
              <span className="text-blue-500 font-bold">
                Quý khách có thể chọn một trong các gói khả dụng dưới đây để
                nâng cấp dịch vụ hiện tại
              </span>
              <div className="flex flex-wrap gap-2.5">
                <div className="w-[286px] bg-white border-[1px] rounded-[10px] drop-shadow flex flex-col">
                  <div className="px-4 py-2 border-b-[1px] flex justify-between items-center">
                    <span className="text-blue-500">VN9-DC3</span>
                    <img
                      src={FlagVN}
                      alt="name_flag"
                      className="w-[20px] object-cover"
                    />
                  </div>
                  <div className="p-4 border-b-[1px] flex justify-center items-center gap-1">
                    <span className="text-[20px] text-red-500">
                      {formatCurrency(1075000)}
                    </span>
                    / tháng
                  </div>
                  <div className="text-[#6B7280] p-4 flex flex-col gap-4">
                    <IconText prefix={<ZingIcon.IconRam />} className="w-[50%]">
                      RAM:
                      <span className="font-[500]">16</span>
                      GB
                    </IconText>
                    <IconText prefix={<ZingIcon.IconCPU />} className="w-[50%]">
                      vCPU:
                      <span className="font-[500]">8</span>
                    </IconText>
                    <IconText
                      prefix={<ZingIcon.IconCPUStorage />}
                      className="w-[50%]"
                    >
                      SSD:
                      <span className="font-[500]">90</span>
                      GB
                    </IconText>
                    <IconText
                      prefix={<ZingIcon.IconWifiVertical />}
                      className="w-[50%]"
                    >
                      Wifi:
                      <span className="font-[500]">Unlimited</span>
                    </IconText>
                    <IconText
                      prefix={<ZingIcon.IconNetwork />}
                      className="w-[50%]"
                    >
                      Mbps:
                      <span className="font-[500]">800</span>
                    </IconText>
                    <Button
                      className="bg-green-500 p-2"
                      prefix={<ZingIcon.IconSettings />}
                      onClick={() =>
                        navigate(
                          `${SERVICE_CLOUD_VPS_URL.upgradePayment.path}/${id}`,
                        )
                      }
                    >
                      Nâng cấp ngay
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default UpgradeVPSPage;

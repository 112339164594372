import { Row } from 'tera-dls';
import StatisticCashExpense from './containers/StatisticCashExpense';
import StatisticCashExpenseByPaymentMethod from './containers/StatisticCashExpenseByPaymentMethod';
import StatisticCashExpenseByTime from './containers/StatisticCashExpenseByTime';
import StatisticCashExpenseByCustomer from './containers/StatisticCustomer';

const Statistic = () => {
  return (
    <div className="p-2.5">
      <div className="font-semibold	text-base uppercase mb-2.5">
        Thống kê thu chi
      </div>
      <Row className="grid xl:grid-cols-2 gap-[16px]">
        <StatisticCashExpense />
        <StatisticCashExpenseByTime />
        <StatisticCashExpenseByPaymentMethod />
        <StatisticCashExpenseByCustomer />
      </Row>
    </div>
  );
};

export default Statistic;

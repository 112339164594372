import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useForm } from 'react-hook-form';
import { Modal, Spin } from 'tera-dls';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const ServiceRenewal = (props: IProps) => {
  const { open, onClose } = props;
  const form = useForm();
  const {
    handleSubmit,
    formState: { isDirty },
  } = form;

  const confirm = useConfirm();

  const handleCancel = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>Bạn có chắc muốn thoát bản ghi này không?</p>
            <p>Sau khi thoát dữ liệu sẽ không được lưu.</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
      return;
    }
    onClose();
  };

  const handleSubmitForm = (values) => {
    handleCancel();
    console.log(values);
  };

  return (
    <Modal
      width={500}
      open={open}
      title="Gia hạn VPS"
      closeIcon={false}
      okText="Lưu"
      cancelText="Hủy"
      maskClosable={false}
      onOk={handleSubmit(handleSubmitForm)}
      onCancel={handleCancel}
    >
      <Spin spinning={false}>
        <FormTera form={form} isLoading={false}>
          <FormTeraItem label="IP" name="ip">
            <Input disabled value="192.168.1.253" />
          </FormTeraItem>
          <FormTeraItem label="Mã giảm giá" name="code">
            <Input />
          </FormTeraItem>
          <FormTeraItem label="Thời hạn (ngày)" name="date">
            <Select
              options={[
                {
                  label: '30 ngày',
                  value: 30,
                },
                {
                  label: '60 ngày',
                  value: 60,
                },
              ]}
            />
          </FormTeraItem>
          <FormTeraItem label="Thành tiền" name="total">
            <InputNumber disabled className="text-red-500" value={50000} />
          </FormTeraItem>
        </FormTera>
      </Spin>
    </Modal>
  );
};

export default ServiceRenewal;

import { useMutation, useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import ZingIcon from '_common/component/ZingIcon';
import { PAYMENT_METHOD_TYPE } from '_common/constants/invoiceManagement';
import { messageWarning } from '_common/constants/message';
import FormTera from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ImgEmpty from 'styles/images/empty-img.png';
import customTwMerge from 'tailwind-merge.config';
import {
  ArrowSmallLeftSolid,
  BookmarkOutlined,
  Breadcrumb,
  Button,
  Col,
  Row,
  Spin,
  notification,
} from 'tera-dls';
import PaymentMethodAPI from '../../apis';
import { FINANCE_PAYMENT_METHOD_URL } from '../../url';
import FormCreateUpdate from './components/FormCreateUpdate';

const listPaymentType = [
  {
    type: PAYMENT_METHOD_TYPE.BANK,
    icon: (
      <i className="stroke-blue-600 w-8 h-8">
        <ZingIcon.IconBank />
      </i>
    ),
  },
  {
    type: PAYMENT_METHOD_TYPE.E_WALLET,
    icon: (
      <i className="fill-blue-600 w-8 h-8">
        <ZingIcon.IconEWallet />
      </i>
    ),
  },
];
const activePaymentTypeClasses = 'border-blue-600 bg-blue-100';

const CreateUpdatePage = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      method_type: PAYMENT_METHOD_TYPE.BANK,
      type: null,
      type_id: '',
      account_number: '',
      account_name: '',
      description: '',
    },
  });
  const { isDirty } = form.formState;
  const { id } = useParams();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Phương thức thanh toán
        </span>
      ),
      onClick: () =>
        isDirty
          ? confirm.warning({
              title: 'Thoát bản ghi',
              content: (
                <>
                  <p>{messageWarning.WARNING_EXIT_1}</p>
                  <p>{messageWarning.WARNING_EXIT_2}</p>
                </>
              ),
              onOk: () => navigate(FINANCE_PAYMENT_METHOD_URL.list.path),
            })
          : navigate(FINANCE_PAYMENT_METHOD_URL.list.path),
    },
    {
      title: `${id ? 'Sửa' : 'Thêm'} Phương thức thanh toán`,
    },
  ];

  const handleSelectMethodType = (type: PAYMENT_METHOD_TYPE) => {
    form.reset({
      method_type: type,
      type: null,
      type_id: '',
      account_number: '',
      account_name: '',
      description: '',
    });
  };

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-finance-payment-method-detail', id],
    () => PaymentMethodAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  useEffect(() => {
    if (!_.isEmpty(dataDetail))
      form.reset({
        ...form.getValues(),
        method_type: dataDetail?.type.type,
        type: dataDetail?.type,
        type_id: dataDetail?.type.id,
        account_number: dataDetail?.account_number,
        account_name: dataDetail?.account_name,
        description: dataDetail?.description,
      });
  }, [dataDetail]);

  const { mutate: mutateCreateUpdate, isLoading: isLoadingCreateUpdate } =
    useMutation(
      (params: any) =>
        id
          ? PaymentMethodAPI.update({ id, params })
          : PaymentMethodAPI.create({ params }),
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            notification.success({
              message: res?.msg,
            });
            navigate(FINANCE_PAYMENT_METHOD_URL.list.path);
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleSubmitForm = (values: any) => {
    if (isLoadingCreateUpdate) return;

    delete values.type;

    mutateCreateUpdate({
      ...values,
      account_name: values.account_name.toUpperCase(),
      account_id: 0,
    });
  };

  return (
    <div className="tera-page-form min-h-[calc(100vh_-_45px)]">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
          <div className="page-header-v2__function">
            <Button
              type="success"
              prefix={<BookmarkOutlined className="w-4" />}
              onClick={form.handleSubmit(handleSubmitForm)}
              loading={isLoadingCreateUpdate}
            >
              Lưu
            </Button>
          </div>
        </div>
      </div>
      <Spin spinning={!_.isNil(id) && isLoadingDetail}>
        {id && (_.isEmpty(dataDetail) || isErrorDetail) ? (
          <NoData />
        ) : (
          <Row className="px-2 xmd:grid-cols-3 gap-4">
            {!id && (
              <div className="w-full p-4 bg-white">
                <h3 className="text-blue-500 font-medium mb-4">
                  Phương thức thanh toán
                </h3>
                <Row className="grid-cols-2 lg:grid-cols-3 gap-4">
                  {listPaymentType.map((paymentType, index) => (
                    <Button
                      key={index}
                      className={customTwMerge(
                        'bg-white hover:bg-white border-2 rounded-2xl flex items-center justify-center py-3.5',
                        _.isEqual(
                          form.watch('method_type'),
                          paymentType.type,
                        ) && activePaymentTypeClasses,
                      )}
                      onClick={() => handleSelectMethodType(paymentType.type)}
                    >
                      {paymentType.icon}
                    </Button>
                  ))}
                </Row>
              </div>
            )}
            <Col className="xmd:col-span-2 w-full p-4 bg-white">
              <h3 className="text-blue-500 font-medium mb-4">
                Thông tin chi tiết
              </h3>
              <FormTera form={form} className="grid grid-cols-12 gap-4">
                <Col
                  className={
                    _.isEqual(
                      form.watch('method_type'),
                      PAYMENT_METHOD_TYPE.BANK,
                    )
                      ? 'col-span-8'
                      : 'col-span-12'
                  }
                >
                  <FormCreateUpdate form={form} />
                </Col>
                {_.isEqual(
                  form.watch('method_type'),
                  PAYMENT_METHOD_TYPE.BANK,
                ) && (
                  <Col className="space-y-4 col-span-4">
                    <h4>Logo</h4>
                    <div className="h-[156px] flex items-center justify-center">
                      <img
                        src={
                          (form.watch('type')?.image ??
                            form.watch('type')?.image_bank) ||
                          ImgEmpty
                        }
                        width={
                          form.watch('type')?.image ??
                          form.watch('type')?.image_bank
                            ? 150
                            : undefined
                        }
                      />
                    </div>
                  </Col>
                )}
              </FormTera>
            </Col>
          </Row>
        )}
      </Spin>
    </div>
  );
};

export default CreateUpdatePage;

import { useMutation } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import NoData from '_common/component/NoData';
import CheckBox from '_common/dof/Control/CheckBox';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import DomainAPI from 'pages/ServiceManagement/Domain/apis';
import {
  DOMAIN_AVAILABLE_COLOR,
  DOMAIN_AVAILABLE_TEXT,
} from 'pages/ServiceManagement/Domain/constants';
import { Fragment, useEffect, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Button, Row, Spin, Tag, formatCurrency, notification } from 'tera-dls';

interface IFormAddProps {
  form: UseFormReturn<any>;
  id: string;
}

const FormCreateUpdate = ({ form, id }: IFormAddProps): JSX.Element => {
  const {
    mutate: mutateDomainLookup,
    isLoading: isLoadingDomainLookup,
    isError: isErrorDomainLookup,
  } = useMutation((name: string) => DomainAPI.checkDomainAvailable({ name }), {
    onSuccess: (res) => {
      if (res?.code === 200) {
        form.setValue('domainLookup', res.data);

        if (_.isEmpty(res.data)) return;

        const domainPricing = res.data.periods?.find(
          (period: any) => period.period == form.watch('period'),
        );

        form.reset({
          ...form.getValues(),
          name: res.data.name,
          label: res.data.sld,
          tld: res.data.tld,
          periods: res.data.periods,
          domain_pricing: domainPricing,
          first_payment: domainPricing?.register,
          recurring_amount: domainPricing?.register,
        });
      }
    },
    onError: (error: any) =>
      notification.error({
        message: error?.message,
      }),
  });

  useEffect(() => {
    if (!_.isNil(id) && form.watch('name'))
      mutateDomainLookup(form.watch('name'));
  }, [id, form.watch('name')]);

  const PeriodOptions = useMemo(
    () =>
      form.watch('domainLookup')?.periods?.map((pricing, index) => ({
        value: pricing.period,
        label: `${index + 1} năm - ${formatCurrency(pricing.register)}`,
      })),
    [form.watch('domainLookup')],
  );

  return (
    <Spin spinning={isLoadingDomainLookup}>
      <CardDetail
        title="Chi tiết tên miền đã chọn"
        className="w-full p-4 flex flex-col gap-6"
        titleClassName="mb-0"
      >
        {id ? (
          <FormTeraItem label="Tên miền" name="name" className="flex-1 mb-0">
            <Input disabled />
          </FormTeraItem>
        ) : (
          <div className="w-full flex items-center gap-2">
            <div className="flex-1 bg-gray-100 border-[1px] rounded-[4px] flex items-center">
              <span className="p-2">www.</span>
              <FormTeraItem name="label" className="flex-1 mb-0">
                <Input className="border-none" />
              </FormTeraItem>
            </div>
            <FormTeraItem name="tld" className="w-[200px] mb-0">
              <Select
                options={[
                  { value: '.vn', label: '.vn' },
                  { value: '.com', label: '.com' },
                  { value: '.net', label: '.net' },
                ]}
              />
            </FormTeraItem>
            <Button
              htmlType="button"
              className="bg-blue-500"
              onClick={() =>
                mutateDomainLookup(`${form.watch('label')}${form.watch('tld')}`)
              }
              disabled={_.isEmpty(form.watch('label'))}
            >
              Kiểm tra
            </Button>
          </div>
        )}
        {isErrorDomainLookup || _.isEmpty(form.watch('domainLookup')) ? (
          <NoData />
        ) : (
          <Fragment>
            <Row className="flex items-center gap-2">
              <Tag
                color={
                  DOMAIN_AVAILABLE_COLOR[form.watch('domainLookup').avaliable]
                }
              >
                {DOMAIN_AVAILABLE_TEXT[form.watch('domainLookup').avaliable]}
              </Tag>
              {!form.watch('domainLookup').avaliable && (
                <span className="text-blue-500 cursor-pointer">WHOIS</span>
              )}
            </Row>
            <Row className="grid sm:grid-cols-2 xl:grid-cols-3 gap-2.5">
              <FormTeraItem label="Chu kỳ" name="period" className="mb-0">
                <Select options={PeriodOptions} />
              </FormTeraItem>
              <FormTeraItem
                label="Giá"
                name="price"
                className="flex flex-col mb-0"
                childrenClassName="h-full"
              >
                <div className="h-full bg-gray-100 px-2 py-2 border-[1px] border-gray-200 rounded-xsm flex items-center">
                  {form.watch('domain_pricing')?.before > -1 ? (
                    <div className="flex gap-3">
                      <span>
                        {formatCurrency(form.watch('domain_pricing')?.register)}
                      </span>
                      <del className="text-[#6B7280]">
                        {formatCurrency(form.watch('domain_pricing')?.before)}
                      </del>
                    </div>
                  ) : (
                    <span>
                      {formatCurrency(form.watch('domain_pricing')?.register)}
                    </span>
                  )}
                </div>
              </FormTeraItem>
              <FormTeraItem
                label="Phí cài đặt"
                name="installation_fee"
                className="mb-0"
              >
                <div className="h-full bg-gray-100 px-2 py-2 border-[1px] border-gray-200 rounded-xsm flex items-center">
                  {formatCurrency(0)}
                </div>
              </FormTeraItem>
              <FormTeraItem
                label="Số tiền thanh toán lần đầu"
                name="first_payment"
                className="mb-0"
              >
                <div className="h-full bg-gray-100 px-2 py-2 border-[1px] border-gray-200 rounded-xsm flex items-center">
                  {formatCurrency(form.watch('first_payment'))}
                </div>
              </FormTeraItem>
              <FormTeraItem
                label="Số tiền thanh toán định kỳ"
                name="recurring_amount"
                className="mb-0"
              >
                <div className="h-full bg-gray-100 px-2 py-2 border-[1px] border-gray-200 rounded-xsm flex items-center">
                  {formatCurrency(form.watch('recurring_amount'))}
                </div>
              </FormTeraItem>

              <FormTeraItem
                label="Lệ phí đăng ký tên miền"
                name="first_payment"
                className="mb-0"
              >
                <div className="h-full bg-gray-100 px-2 py-2 border-[1px] border-gray-200 rounded-xsm flex items-center">
                  {formatCurrency(form.watch('first_payment'))}
                </div>
              </FormTeraItem>
              <FormTeraItem
                label="Phí duy trì tên miền"
                name="recurring_amount"
                className="mb-0"
              >
                <div className="h-full bg-gray-100 px-2 py-2 border-[1px] border-gray-200 rounded-xsm flex items-center">
                  {formatCurrency(form.watch('recurring_amount'))}
                </div>
              </FormTeraItem>
              <div className="flex gap-x-5">
                <FormTeraItem name="idprotection" className="pt-6 mb-0">
                  <CheckBox>Được bảo vệ</CheckBox>
                </FormTeraItem>
                <FormTeraItem name="autorenew" className="pt-6 mb-0">
                  <CheckBox>Tự động gia hạn</CheckBox>
                </FormTeraItem>
              </div>
            </Row>
          </Fragment>
        )}
      </CardDetail>
    </Spin>
  );
};

export default FormCreateUpdate;

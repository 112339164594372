import { useMutation, useQueryClient } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import TextNavigate from '_common/component/TextNavigate';
import ZingIcon from '_common/component/ZingIcon';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { USER_ACCOUNT_URL } from 'pages/UserManagement/Account/url';
import { useState } from 'react';
import ServiceAPI from 'states/api/ServiceAPI';
import { Button, ColumnsType, Tag, formatDate, notification } from 'tera-dls';
import {
  CLOUD_VPS_STATUS,
  TABLE_STATUS_COLOR,
  TABLE_STATUS_TEXT,
} from '../constants';
import { SERVICE_CLOUD_VPS_URL } from '../url';
import TableRowExpand from './TableRowExpand';

const Table = (props: ITeraTableProps): JSX.Element => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateReboot, isLoading: isLoadingReboot } = useMutation(
    (id: any) => ServiceAPI.reboot({ id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          queryClient.invalidateQueries(['get-service-cloud-vps-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleReboot = (record: any) =>
    confirm.warning({
      title: 'Xác nhận khởi động lại dịch vụ',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn khởi động lại dịch vụ</p>
          <p>
            <b>{record?.product.planCode}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateReboot(record?.id),
    });

  const { mutate: mutateDiag, isLoading: isLoadingDiag } = useMutation(
    (id: any) => ServiceAPI.runDiag({ id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          queryClient.invalidateQueries(['get-service-cloud-vps-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDiag = (record: any) =>
    confirm.warning({
      title: 'Xác nhận khởi động lại dịch vụ',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xác nhận khắc phục sự cố VPS</p>
          <p>
            <span className="font-bold">{record?.product.planCode}</span> này
            không?
          </p>
        </div>
      ),
      onOk: () => mutateDiag(record?.id),
    });

  const { mutate: mutateUpdateStatus, isLoading: isLoadingUpdateStatus } =
    useMutation(
      (params: any) =>
        _.isEqual(params.status, CLOUD_VPS_STATUS.RUNNING)
          ? ServiceAPI.active({ id: params.id })
          : ServiceAPI.deactivate({ id: params.id }),
      {
        onSuccess: (res) => {
          if (_.isEqual(res?.code, 200)) {
            queryClient.invalidateQueries(['get-service-cloud-vps-list']);
            queryClient.invalidateQueries([
              'get-summary-service-cloud-vps-list',
            ]);
            notification.success({
              message: res?.msg,
            });
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleUpdateStatus = (record: any, status: CLOUD_VPS_STATUS) =>
    confirm.warning({
      title: `Xác nhận ${
        _.isEqual(status, CLOUD_VPS_STATUS.RUNNING) ? '' : 'hủy'
      } kích hoạt dịch vụ`,
      content: (
        <div className="flex flex-col">
          <p>
            {`Bạn có chắc muốn ${
              _.isEqual(status, CLOUD_VPS_STATUS.RUNNING) ? '' : 'hủy'
            } kích hoạt dịch vụ`}
          </p>
          <p>
            <b>{record?.product.planCode}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateUpdateStatus({ id: record.id, status: status }),
    });

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation(
    (id: any) => ServiceAPI.delete({ id }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          queryClient.invalidateQueries(['get-service-cloud-vps-list']);
          queryClient.invalidateQueries(['get-summary-service-cloud-vps-list']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleDelete = (record: any) =>
    confirm.warning({
      title: 'Xác nhận xóa dịch vụ',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa dịch vụ</p>
          <p>
            <b>{record?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => mutateDelete(record?.id),
    });

  const columns: ColumnsType<any> = [
    {
      title: 'IP',
      dataIndex: 'ip',
      width: 140,
      render: (ip, record) => (
        <TextNavigate
          to={`${SERVICE_CLOUD_VPS_URL.detail.path}/${record.id}`}
          className="flex items-center gap-2"
        >
          <ZingIcon.IconLocation className="size-4" color="#3f83f8" />
          {ip}
        </TextNavigate>
      ),
    },
    {
      title: 'Vị trí',
      dataIndex: 'location',
      width: 150,
      render: (location) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconSquareBox className="size-4" color="#3f83f8" />
          {location?.locationName}
        </div>
      ),
    },
    {
      title: 'Tên',
      dataIndex: 'planCode',
      width: 175,
      render: (_: any, record: any) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconEarth className="size-4" color="#3f83f8" />
          {record.product.planCode}
          <img
            src={record.country.avatar_url}
            alt="name_flag"
            className="size-4 rounded-[1rem] object-cover"
          />
        </div>
      ),
    },
    {
      title: 'OS',
      dataIndex: 'os',
      width: 200,
      render: (os) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconWindows className="size-4" color="#3f83f8" />
          {os?.osName}
        </div>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'date_created',
      width: 175,
      render: (date_created) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconCalendarCheck className="size-4" color="#3f83f8" />
          {formatDate(date_created)}
        </div>
      ),
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expires',
      width: 175,
      render: (expires) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconCalendarEmpty className="size-4" color="#3f83f8" />
          {formatDate(expires)}
        </div>
      ),
    },
    {
      title: 'Người dùng',
      dataIndex: 'registrant',
      width: 150,
      render: (_: any, record: any) => (
        <HoverQuickView
          avatarUrl={record.registrant?.user.avatar}
          name={record.registrant?.user.full_name}
          email={record.registrant?.email}
          phone={record.registrant?.phone}
        >
          <TextNavigate
            to={`${USER_ACCOUNT_URL.detail.path}/${record.registrant?.id}`}
          >
            {record.registrant?.user.full_name}
          </TextNavigate>
        </HoverQuickView>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconCompass className="size-4" color="#3f83f8" />
          <Tag color={TABLE_STATUS_COLOR[status]} className="font-[500]">
            {TABLE_STATUS_TEXT[status]}
          </Tag>
        </div>
      ),
    },
    {
      fixed: 'right',
      width: 150,
      render: (record: any) => {
        return (
          <Button
            className="bg-green-500 p-2 hover:bg-green-600"
            prefix={<ZingIcon.IconSettings />}
            onClick={() =>
              setExpandedRowKeys(
                expandedRowKeys.includes(record.id)
                  ? expandedRowKeys.filter((k) => k !== record.id)
                  : [...expandedRowKeys, record.id],
              )
            }
          >
            Cloud Control
          </Button>
        );
      },
    },
  ];

  return (
    <TableTera
      rowKey="id"
      columns={columns}
      loading={
        props?.loading ||
        isLoadingReboot ||
        isLoadingDiag ||
        isLoadingUpdateStatus ||
        isLoadingDelete
      }
      expandable={{
        expandedRowKeys,
        expandIconColumnIndex: -1,
        expandedRowRender: (record: any) => (
          <TableRowExpand
            record={record}
            handleReboot={handleReboot}
            handleDiag={handleDiag}
            handleUpdateStatus={handleUpdateStatus}
            handleDelete={handleDelete}
          />
        ),
      }}
      className="[&_.tera-table-expanded-row-fixed]:px-0 center-table"
      rowClassName={(_, index) =>
        index % 2 === 0 ? '!bg-white' : '!bg-[#f9fafb]'
      }
      {...props}
    />
  );
};

export default Table;

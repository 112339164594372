import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import _ from 'lodash';
import { useEffect } from 'react';
import { formatDate, Modal, Spin } from 'tera-dls';
import DatacenterAPI from '../apis';

interface IModalModalCreateUpdateProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

const ModalDetail = ({
  open,
  close,
  id,
}: IModalModalCreateUpdateProps): JSX.Element => {
  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-package-datacenter-detail', id],
    () => DatacenterAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  const details = [
    { label: 'Mã datacenter', value: dataDetail?.datacenterId },
    { label: 'Tên datacenter', value: dataDetail?.datacenterName },
    { label: 'Quốc gia', value: dataDetail?.country.countryName },
    {
      label: 'Vị trí',
      value: dataDetail?.locations
        .map((location: any) => location.location.locationName)
        .join(', '),
    },
    {
      label: 'Ngày tạo',
      value: formatDate(dataDetail?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Ngày cập nhật',
      value: formatDate(dataDetail?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  return (
    <Modal
      title="Chi tiết Datacenter"
      open={open}
      centered={true}
      destroyOnClose
      cancelButtonProps={{ className: 'hidden' }}
      okText="Đóng"
      onOk={close}
      contentClassName="sm:w-[500px] h-full"
      bodyClassName="!py-4"
    >
      <Spin spinning={id && isLoadingDetail}>
        {id && isErrorDetail ? (
          <NoData />
        ) : (
          <div className="flex flex-col gap-2">
            {details.map((item, index) => (
              <div className="flex" key={index}>
                <span className="flex-1">{item.label}</span>
                <span className="flex-1">{item.value}</span>
              </div>
            ))}
          </div>
        )}
      </Spin>
    </Modal>
  );
};

export default ModalDetail;

import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import _ from 'lodash';
import { SERVICE_TYPE_TEXT } from '_common/constants/serviceManagement';
import { useEffect } from 'react';
import { formatDate, Modal, Spin, Tag } from 'tera-dls';
import ServicePackageAPI from '../apis';
import { TABLE_STATUS_COLOR, TABLE_STATUS_TEXT } from '../contants';

interface IModalModalCreateUpdateProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

const ModalDetail = ({
  open,
  close,
  id,
}: IModalModalCreateUpdateProps): JSX.Element => {
  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-package-detail', id],
    () => ServicePackageAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  const details = [
    { label: 'Mã sản phẩm', value: dataDetail?.planId },
    { label: 'Tên sản phẩm', value: dataDetail?.planCode },
    { label: 'Loại sản phẩm', value: SERVICE_TYPE_TEXT[dataDetail?.planType] },
    { label: 'Quốc gia', value: dataDetail?.countryName },
    {
      label: 'Vị trí',
      value: dataDetail?.locations
        .map((location: any) => location.location.locationName)
        .join(', '),
    },
    {
      label: 'Datacenter',
      value: dataDetail?.datacenters
        .map((datacenter: any) => datacenter.datacenter.datacenterName)
        .join(', '),
    },
    {
      label: 'Hệ điều hành',
      value: dataDetail?.operating_systems
        .map((os: any) => os.os.osName)
        .join(', '),
    },
    { label: 'Ram', value: dataDetail?.ram },
    { label: 'CPU', value: dataDetail?.cpu },
    { label: 'SSD', value: dataDetail?.ssd },
    { label: 'Bandwidth', value: dataDetail?.bandwidth },
    { label: 'Ethernet Port', value: dataDetail?.ethernetPort },
    { label: 'Backup', value: dataDetail?.backup },
    {
      label: 'Trạng thái',
      value: (
        <Tag
          color={TABLE_STATUS_COLOR[dataDetail?.active]}
          className="font-[500]"
        >
          {TABLE_STATUS_TEXT[dataDetail?.active]}
        </Tag>
      ),
    },
    {
      label: 'Ngày tạo',
      value: formatDate(dataDetail?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Ngày cập nhật',
      value: formatDate(dataDetail?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  return (
    <Modal
      title="Chi tiết sản phẩm"
      open={open}
      centered={true}
      destroyOnClose
      cancelButtonProps={{ className: 'hidden' }}
      okText="Đóng"
      onOk={close}
      contentClassName="sm:w-[500px] h-full"
      bodyClassName="!py-4"
    >
      <Spin spinning={id && isLoadingDetail}>
        {id && isErrorDetail ? (
          <NoData />
        ) : (
          <div className="flex flex-col gap-2">
            {details.map((item, index) => (
              <div className="flex" key={index}>
                <span className="flex-1">{item.label}</span>
                <span className="flex-1">{item.value}</span>
              </div>
            ))}
          </div>
        )}
      </Spin>
    </Modal>
  );
};

export default ModalDetail;

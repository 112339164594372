import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { messageValidate, messageWarning } from '_common/constants/message';
import {
  PRICE_PERIOD_TEXT,
  SERVICE,
  SERVICE_OPTIONS,
} from '_common/constants/serviceManagement';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCountry from '_common/dof/Select/SelectCountry';
import SelectDatacenter from '_common/dof/Select/SelectDatacenter';
import SelectLocation from '_common/dof/Select/SelectLocation';
import SelectOperatingSystem from '_common/dof/Select/SelectOperatingSystem';
import SelectPlanSubtype from '_common/dof/Select/SelectPlanSubtype';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Modal, OptionProps, Row, Spin, notification } from 'tera-dls';
import ServicePackageAPI from '../apis';

interface IModalModalCreateUpdateProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

const ModalCreateUpdate = ({
  open,
  close,
  id,
}: IModalModalCreateUpdateProps): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      planId: '',
      planCode: '',
      planType: SERVICE.CLOUD_VPS,
      planSubtype: '',
      countryCode: '',
      countryName: '',
      locations: [],
      datacenters: [],
      os: [],
      ram: '',
      cpu: '',
      ssd: '',
      bandwidth: '',
      ethernetPort: '',
      backup: '',
      daily: '',
      threeDays: '',
      weekly: '',
      monthly: '',
      twoMonths: '',
      threeMonths: '',
      sixMonths: '',
      oneYear: '',
      twoYears: '',
      threeYears: '',
      fourYears: '',
      fiveYears: '',
    },
  });
  const { isDirty } = form.formState;
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-package-detail', id],
    () => ServicePackageAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  useEffect(() => {
    if (!_.isEmpty(dataDetail))
      form.reset({
        ...form.getValues(),
        planId: dataDetail?.planId,
        planCode: dataDetail?.planCode,
        planType: dataDetail?.planType,
        countryCode: dataDetail?.countryCode,
        countryName: dataDetail?.countryName,
        locations: dataDetail?.locations.map(
          (location: any) => location.locationId,
        ),
        datacenters: dataDetail?.datacenters.map(
          (datacenter: any) => datacenter.datacenterId,
        ),
        os: dataDetail?.operating_systems.map((os: any) => os.osId),
        ram: dataDetail?.ram,
        cpu: dataDetail?.cpu,
        ssd: dataDetail?.ssd,
        bandwidth: dataDetail?.bandwidth,
        ethernetPort: dataDetail?.ethernetPort,
        backup: dataDetail?.backup,
        daily: dataDetail?.daily,
        threeDays: dataDetail?.threeDays,
        weekly: dataDetail?.weekly,
        monthly: dataDetail?.monthly,
        twoMonths: dataDetail?.twoMonths,
        threeMonths: dataDetail?.threeMonths,
        sixMonths: dataDetail?.sixMonths,
        oneYear: dataDetail?.oneYear,
        twoYears: dataDetail?.twoYears,
        threeYears: dataDetail?.threeYears,
        fourYears: dataDetail?.fourYears,
        fiveYears: dataDetail?.fiveYears,
      });
  }, [dataDetail]);

  const { mutate: mutateCreateUpdate, isLoading: isLoadingMutate } =
    useMutation(
      (params) =>
        id
          ? ServicePackageAPI.update({ id, params })
          : ServicePackageAPI.create({ params }),
      {
        onSuccess: (res) => {
          if (_.isEqual(res?.code, 200)) {
            notification.success({
              message: res?.msg,
            });
            queryClient.invalidateQueries(['get-service-package-list']);
            close();
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleSubmitForm = (values: any) => {
    if ((id && isLoadingDetail) || isLoadingMutate) return;
    mutateCreateUpdate(values);
  };

  const renderPrices = () =>
    Object.keys(PRICE_PERIOD_TEXT).map((priceKey, index) => (
      <FormTeraItem
        key={index}
        label={PRICE_PERIOD_TEXT[priceKey]}
        name={priceKey}
        className="mb-0 flex items-center gap-2"
        labelClassName="w-[15%] text-right mb-0"
      >
        <InputNumber />
      </FormTeraItem>
    ));

  return (
    <Modal
      title={`${id ? 'Sửa' : 'Thêm mới'} sản phẩm`}
      open={open}
      centered={true}
      destroyOnClose
      okText="Đồng ý"
      cancelText="Hủy"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={() =>
        isDirty
          ? confirm.warning({
              title: 'Thoát bản ghi',
              content: (
                <>
                  <p>{messageWarning.WARNING_EXIT_1}</p>
                  <p>{messageWarning.WARNING_EXIT_2}</p>
                </>
              ),
              onOk: close,
            })
          : close()
      }
      className="sm:w-[90%] xl:w-[80%]"
      bodyClassName="!py-4"
      confirmLoading={isLoadingMutate}
    >
      <Spin spinning={!_.isNil(id) && isLoadingDetail}>
        {id && isErrorDetail ? (
          <NoData />
        ) : (
          <FormTera form={form} className="flex flex-col gap-2">
            <Row className="flex">
              <Col className="flex-1 flex flex-col gap-2">
                <FormTeraItem
                  label="Mã sản phẩm"
                  name="planId"
                  className="mb-0"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                    },
                  ]}
                >
                  <Input />
                </FormTeraItem>
                <FormTeraItem
                  label="Tên sản phẩm"
                  name="planCode"
                  className="mb-0"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                    },
                  ]}
                >
                  <Input />
                </FormTeraItem>
                <FormTeraItem
                  label="Loại sản phẩm"
                  name="planType"
                  className="mb-0"
                >
                  <Select options={[SERVICE_OPTIONS[4]]} />
                </FormTeraItem>
                {[
                  SERVICE.EMAIL,
                  SERVICE.HOSTING,
                  SERVICE.CLOUD_SERVER,
                  SERVICE.SSL,
                ].includes(form.watch('planType')) && (
                  <FormTeraItem
                    label="Loại phụ"
                    name="planSubtype"
                    className="mb-0"
                    rules={[
                      {
                        required: messageValidate.emptySelect,
                      },
                    ]}
                  >
                    <SelectPlanSubtype
                      paramsApi={{ type: form.watch('planType') }}
                      allowClear={false}
                    />
                  </FormTeraItem>
                )}
                <FormTeraItem
                  label="Quốc gia"
                  name="countryCode"
                  className="mb-0"
                  rules={[
                    {
                      required: messageValidate.emptySelect,
                    },
                  ]}
                >
                  <SelectCountry
                    paramsApi={{ include_id: form.watch('countryCode') }}
                    onSelect={(value: OptionProps) =>
                      form.setValue('countryName', value.label)
                    }
                    allowClear={false}
                  />
                </FormTeraItem>
                <FormTeraItem
                  label="Vị trí"
                  name="locations"
                  className="mb-0"
                  rules={[
                    {
                      required: messageValidate.emptySelect,
                    },
                  ]}
                >
                  <SelectLocation
                    mode="multiple"
                    paramsApi={{
                      country: form.watch('countryCode'),
                      include_id: form.watch('locations').toString(),
                    }}
                    allowClear={false}
                  />
                </FormTeraItem>
                <FormTeraItem
                  label="Datacenter"
                  name="datacenters"
                  className="mb-0"
                  rules={[
                    {
                      required: messageValidate.emptySelect,
                    },
                  ]}
                >
                  <SelectDatacenter
                    mode="multiple"
                    paramsApi={{
                      country: form.watch('countryCode'),
                      include_id: form.watch('datacenters').toString(),
                    }}
                    allowClear={false}
                  />
                </FormTeraItem>
                <FormTeraItem
                  label="Hệ điều hành"
                  name="os"
                  className="mb-0"
                  rules={[
                    {
                      required: messageValidate.emptySelect,
                    },
                  ]}
                >
                  <SelectOperatingSystem
                    mode="multiple"
                    paramsApi={{
                      include_id: form.watch('os').toString(),
                    }}
                    allowClear={false}
                  />
                </FormTeraItem>
                <Row className="flex flex-col xmd:flex-row flex-wrap">
                  <FormTeraItem label="Ram" name="ram" className="flex-1 mb-0">
                    <InputNumber />
                  </FormTeraItem>
                  <FormTeraItem label="CPU" name="cpu" className="flex-1 mb-0">
                    <InputNumber />
                  </FormTeraItem>
                </Row>
                <Row className="flex flex-col xmd:flex-row flex-wrap">
                  <FormTeraItem label="SSD" name="ssd" className="flex-1 mb-0">
                    <InputNumber />
                  </FormTeraItem>
                  <FormTeraItem
                    label="Bandwidth"
                    name="bandwidth"
                    className="flex-1 mb-0"
                  >
                    <Input />
                  </FormTeraItem>
                </Row>
                <Row className="flex flex-col xmd:flex-row flex-wrap">
                  <FormTeraItem
                    label="Ethernet Port"
                    name="ethernetPort"
                    className="flex-1 mb-0"
                  >
                    <Input />
                  </FormTeraItem>
                  <FormTeraItem
                    label="Backup"
                    name="backup"
                    className="flex-1 mb-0"
                  >
                    <InputNumber />
                  </FormTeraItem>
                </Row>
              </Col>
              <Col className="flex-1 flex flex-col gap-2">
                <span>Phí dịch vụ</span>
                {renderPrices()}
              </Col>
            </Row>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
};

export default ModalCreateUpdate;

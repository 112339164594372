import { useMutation } from '@tanstack/react-query';
import { useStores } from '_common/hooks';
import useConfirm from '_common/hooks/useConfirm';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from 'states/api';
import { notification } from 'tera-dls';

const LogoutPage = (): JSX.Element => {
  const {
    authStore: { clear },
    commonStore: { clear: clearCRM },
  } = useStores();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: () => {
      clear();
      clearCRM();
      navigate('/');
    },
    onError: (error: any) => {
      clear();
      clearCRM();
      const errorMessage = error?.msg;

      notification.error({
        message: errorMessage,
      });
    },
  });

  useEffect(() => {
    confirm.default({
      title: 'Thoát khỏi hệ thống',
      content: 'Bạn có chắc muốn thoát khỏi hệ thống',
      onOk: () => {
        onLogout();
      },
      onCancel: () => navigate(-1),
    });
  }, []);

  return <></>;
};

export default observer(LogoutPage);

import React, { useState } from 'react';
import Content from './containers/Content';
export const StatisticCashExpenseByTimeParamContext = React.createContext(null);

const StatisticCashExpenseByTime = () => {
  const [contextParams, setContextParams] = useState<any>();
  return (
    <StatisticCashExpenseByTimeParamContext.Provider
      value={{ contextParams, setContextParams }}
    >
      <Content chartClassName="w-full !aspect-[2/1]" />
    </StatisticCashExpenseByTimeParamContext.Provider>
  );
};

export default StatisticCashExpenseByTime;

import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { AREA_CODE_TEXT, DATE_TIME_FORMAT } from '_common/constants/common';
import _ from 'lodash';
import { useEffect } from 'react';
import { formatDate, Modal, Spin, Tag } from 'tera-dls';
import CountryAPI from '../apis';
import { TABLE_STATUS_COLOR, TABLE_STATUS_TEXT } from '../contants';

interface IModalDetailProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

const ModalDetail = ({ open, close, id }: IModalDetailProps): JSX.Element => {
  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-package-country-detail', id],
    () => CountryAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  const details = [
    {
      label: 'Cờ quốc gia',
      value: (
        <img
          src={dataDetail?.avatar_url}
          alt="Country Flag"
          className="w-[30px] h-[24px]"
        />
      ),
    },
    { label: 'Mã quốc gia', value: dataDetail?.countryCode },
    { label: 'Tên quốc gia', value: dataDetail?.countryName },
    { label: 'Khu vực', value: AREA_CODE_TEXT[dataDetail?.areaCode] },
    {
      label: 'Trạng thái',
      value: (
        <Tag
          color={TABLE_STATUS_COLOR[dataDetail?.active]}
          className="font-[500]"
        >
          {TABLE_STATUS_TEXT[dataDetail?.active]}
        </Tag>
      ),
    },
    {
      label: 'Ngày tạo',
      value: formatDate(dataDetail?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Ngày cập nhật',
      value: formatDate(dataDetail?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  return (
    <Modal
      title="Chi tiết quốc gia"
      open={open}
      centered={true}
      destroyOnClose
      cancelButtonProps={{ className: 'hidden' }}
      okText="Đóng"
      onOk={close}
      contentClassName="sm:w-[500px] h-full"
      bodyClassName="!py-4"
    >
      <Spin spinning={id && isLoadingDetail}>
        {id && isErrorDetail ? (
          <NoData />
        ) : (
          <div className="flex flex-col gap-2">
            {details.map((item, index) => (
              <div className="flex" key={index}>
                <span className="flex-1">{item.label}</span>
                <span className="flex-1">{item.value}</span>
              </div>
            ))}
          </div>
        )}
      </Spin>
    </Modal>
  );
};

export default ModalDetail;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { messageWarning } from '_common/constants/message';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ServiceAPI from 'states/api/ServiceAPI';
import { Modal, Spin, notification } from 'tera-dls';

interface IModalNoteProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

const ModalNote = ({ open, close, id }: IModalNoteProps): JSX.Element => {
  const form = useForm({ mode: 'onChange', defaultValues: { note: '' } });
  const { isDirty } = form.formState;
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-cloud-vps-detail', id],
    () => ServiceAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  useEffect(() => {
    if (!_.isEmpty(dataDetail))
      form.reset({
        note: dataDetail?.note,
      });
  }, [dataDetail]);

  const { mutate: mutateUpdateNote, isLoading: isLoadingUpdateNote } =
    useMutation(
      (params: any) =>
        ServiceAPI.updateNote({ id: params.id, params: params.params }),
      {
        onSuccess: (res) => {
          if (_.isEqual(res?.code, 200)) {
            queryClient.invalidateQueries(['get-service-cloud-vps-list']);
            queryClient.invalidateQueries(['get-service-cloud-vps-detail']);
            notification.success({
              message: res?.msg,
            });
          }
        },
        onError: (error: any) =>
          notification.error({
            message: error?.message,
          }),
      },
    );

  const handleUpdateNote = (values: any) =>
    confirm.warning({
      title: 'Xác nhận cập nhật ghi chú dịch vụ',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn cập nhật ghi chú dịch vụ</p>
          <p>
            <b>{dataDetail?.product.planCode}</b> này không?
          </p>
        </div>
      ),
      onOk: () =>
        mutateUpdateNote({
          id: dataDetail?.id,
          params: {
            note: values.note,
          },
        }),
    });

  const handleCloseConfirm = () => {
    isDirty
      ? confirm.warning({
          title: 'Thoát bản ghi',
          content: (
            <>
              <p>{messageWarning.WARNING_EXIT_1}</p>
              <p>{messageWarning.WARNING_EXIT_2}</p>
            </>
          ),
          onOk: () => close(),
        })
      : close();
  };

  return (
    <Modal
      title="Thêm ghi chú cho Cloud VPS"
      open={open}
      centered={true}
      okText="Đồng ý"
      cancelText="Hủy"
      onOk={form.handleSubmit(handleUpdateNote)}
      onCancel={handleCloseConfirm}
      className="sm:w-[500px]"
      bodyClassName="!py-4"
      confirmLoading={isLoadingDetail || isLoadingUpdateNote}
    >
      <Spin spinning={isLoadingDetail || isLoadingUpdateNote}>
        {isErrorDetail ? (
          <NoData />
        ) : (
          <FormTera form={form}>
            <FormTeraItem name="note" className="mb-0">
              <TextArea placeholder="Vui lòng nhập" rows={5} />
            </FormTeraItem>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
};

export default ModalNote;

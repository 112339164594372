import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { ChartProps, Doughnut } from 'react-chartjs-2';
import {
  ArrowTrendingDownOutlined,
  ArrowTrendingUpOutlined,
  ChartDoughnutProps,
  Col,
  CurrencyDollarOutlined,
  Row,
  UserGroupOutlined,
  formatCurrency,
  formatNumber,
  getQueryParams,
} from 'tera-dls';
// import { Plugin } from 'chart.js';
import GridBox from '_common/component/GridBox';
import img_aff_bg_vector_left from 'styles/images/Affiliates/aff_bg_vector_left.png';
import { mockData } from './constant';
import LineChartCustom from './containers/LineChartCustom';
import { useQuery } from '@tanstack/react-query';
import AffiliatesAPI from './_api';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
ChartJS.register(ArcElement, Tooltip, Legend);
const AffiliatesSummaryReport = () => {
  // const NUMBER_CFG = {count: DATA_COUNT, min: 0, max: 100};
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const { data: response } = useQuery(
    ['get-report-publisher', queryParams],
    () => {
      const data = {
        page: 1,
        limit: 10,
        ...queryParams,
      };
      return AffiliatesAPI.getReportPublisher(data);
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );
  //proportionOfUsers, revenues, topUser
  const { marketingMetrics, proportionOfUsers } = response?.data ?? {};

  const dataDoughNut: any = useMemo(() => {
    const arrColor = ['#FF715B', '#6665DD', '#34D1BF'];
    return {
      labels: ['USER', 'AFFILIATES', 'NEW USER'],
      datasets: [
        {
          data: [
            proportionOfUsers?.proportionOldUser ?? 1,
            proportionOfUsers?.proportionPublisher ?? 1,
            proportionOfUsers?.proportionNewUser ?? 1,
          ],
          backgroundColor: arrColor,
        },
      ],
    };
  }, [proportionOfUsers]);

  const optionDoughnut: ChartDoughnutProps['options'] = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            // const label = context.label || '';
            let total = 0;
            const data = context.dataset.data;
            data.forEach(function (value) {
              total += value;
            });
            const currentValue: any = context?.raw || 0;
            const percentage = Math.round(
              (currentValue / total) * 100 + Number.EPSILON,
            );
            return percentage + '%';
          },
        },
      },
    },
  };

  const plugins: ChartProps<'doughnut'>['plugins'] = [
    {
      id: 'chart-doughnut',
      beforeDraw: function (chart) {
        const width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / 160).toFixed(2);
        ctx.font = fontSize + 'em sans-serif';
        ctx.textBaseline = 'top';
        const text = '3986',
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        textY;
        textX;
        ctx.fillText(text, 145, 150);
        ctx.save();
      },
    },
  ];
  return (
    <div className="tera-page">
      <h2 className="font-bold mb-2.5">BÁO CÁO CHUNG</h2>
      <div className=" w-full flex flex-col gap-[30px]">
        <Row className="xl:grid-cols-3">
          <Col className="xl:col-span-2 rounded-[16px] relative over-view-left box-shadow-custom flex items-center">
            <img
              className="absolute right-[2%] top-[50%] -translate-y-1/2 w-1/3 hidden xmd:block"
              src={img_aff_bg_vector_left}
              alt="aff_bg_vector_left"
            />
            <div className="relative flex flex-col gap-14 p-[50px]">
              <h2 className="font-medium text-[24px]">Tổng quan</h2>
              <div className="flex items-center gap-2.5">
                <div className="flex flex-col gap-5">
                  <div className="w-[50px] h-[50px] rounded bg-[#ddedf8]">
                    <UserGroupOutlined className="w-full h-full p-2.5 text-blue-800" />
                  </div>
                  <div className="w-[50px] h-[50px] rounded bg-[#ddedf8]">
                    <CurrencyDollarOutlined className="w-full h-full p-2.5 text-blue-800" />
                  </div>
                </div>
                <div className="flex flex-row gap-10">
                  <div className="flex flex-col gap-8">
                    <div className="text-blue-800 flex justify-center flex-col gap-2">
                      <p className="text-[16px]">Người giới thiệu</p>
                      <div className="flex gap-2.5">
                        <span className="text-[24px] font-bold">478</span>
                        <span className="flex gap-1.5">
                          <ArrowTrendingUpOutlined className="text-green-600 w-[15px] h-[15px]" />
                          10%
                        </span>
                      </div>
                    </div>
                    <div className="text-blue-800 flex justify-center flex-col gap-2">
                      <p className="text-[16px]">Tổng doanh thu (VND)</p>
                      <div className="flex gap-2.5">
                        <span className="text-[24px] font-bold">
                          {formatNumber(298000)}
                        </span>
                        <span className="flex gap-1.5">
                          <ArrowTrendingUpOutlined className="text-green-600 w-[15px] h-[15px]" />
                          10%
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-8">
                    <div className="text-blue-800 flex justify-center flex-col gap-2">
                      <p className="text-[16px]">Tổng</p>
                      <div className="flex gap-2.5">
                        <span className="text-[24px] font-bold">
                          {formatNumber(186000000)}
                        </span>
                        <span className="flex gap-1.5">
                          <ArrowTrendingDownOutlined className="text-red-600 w-[15px] h-[15px]" />
                          10%
                        </span>
                      </div>
                    </div>
                    <div className="text-blue-800 flex justify-center flex-col gap-2">
                      <p className="text-[16px]">Tổng</p>
                      <div className="flex gap-2.5">
                        <span className="text-[24px] font-bold">
                          {formatNumber(186000000)}
                        </span>
                        <span className="flex gap-1.5">
                          <ArrowTrendingUpOutlined className="text-green-600 w-[15px] h-[15px]" />
                          10%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col className="rounded-[16px] box-shadow-custom bg-white py-4 px-8">
            <h2 className="font-medium text-2xl">Tỷ trọng người dùng</h2>
            <div className="w-full">
              <Doughnut
                className="m-auto"
                data={dataDoughNut}
                options={optionDoughnut}
                plugins={plugins}
              />
            </div>
          </Col>
          <GridBox className="xl:col-span-3" value={marketingMetrics} />
        </Row>
        <Row className="xmd:grid-cols-2 2xl:grid-cols-4 gap-5">
          <Col className="col-span-2 box-shadow-custom bg-white rounded-[16px] max-h-[500px]">
            <LineChartCustom />
          </Col>
          <Col className="box-shadow-custom bg-white rounded-[16px] px-8 py-4 h-fit">
            <h3 className="text-2xl font-medium pb-8">Doanh thu gần đây</h3>
            <ul className="flex flex-col gap-2">
              {mockData?.doanhthu.map((item) => {
                return (
                  <li className="flex justify-between pb-8 xmd:pb-10 xl:pb-[50px] border-b-[#E5E7EB] border-b-[1px]">
                    <span className="text-[#3F83F8] font-medium">
                      {item?.time}
                    </span>
                    <span>{formatCurrency(item?.total)}</span>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col className="box-shadow-custom bg-white rounded-[16px] px-8 py-4 h-fit">
            <h3 className="text-2xl font-medium pb-8">Top người dùng</h3>
            <ul className="flex flex-col gap-2">
              {mockData?.top.map((item) => {
                return (
                  <li className="flex justify-between pb-8 xmd:pb-10 xl:pb-[50px] border-b-[#E5E7EB] border-b-[1px]">
                    <span>{item?.name}</span>
                    <span className="text-[#3F83F8] font-medium">
                      {formatCurrency(item?.total)}
                    </span>
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AffiliatesSummaryReport;

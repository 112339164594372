import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import TextNavigate from '_common/component/TextNavigate';
import { messageWarning } from '_common/constants/message';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import ServiceAPI from 'states/api/ServiceAPI';
import { Modal, Spin, notification } from 'tera-dls';
import { deleteOptions } from '../constants';
import { SERVICE_CLOUD_VPS_URL } from '../url';

interface IModalChangeIPProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

const ModalChangeIP = ({
  open,
  close,
  id,
}: IModalChangeIPProps): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: { locationId: '', osId: '', delete_option: 1 },
  });
  const { isDirty } = form.formState;
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const {
    data: dataDetail,
    refetch: refetchDetail,
    isLoading: isLoadingDetail,
    isError: isErrorDetail,
  } = useQuery(
    ['get-service-cloud-vps-detail', id],
    () => ServiceAPI.getDetail({ id }),
    {
      enabled: !_.isNil(id),
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetchDetail();
  }, [id]);

  useEffect(() => {
    if (!_.isEmpty(dataDetail))
      form.reset({
        ...form.getValues(),
        locationId: dataDetail?.location.locationId,
        osId: dataDetail?.os.osId,
      });
  }, [dataDetail]);

  const { mutate: mutateChangeIP, isLoading: isLoadingChangeIP } = useMutation(
    (params: any) =>
      ServiceAPI.changeIP({ id: params.id, params: params.params }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          queryClient.invalidateQueries(['get-service-cloud-vps-list']);
          queryClient.invalidateQueries(['get-service-cloud-vps-detail']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleChangeIP = (values: any) =>
    confirm.warning({
      title: 'Xác nhận thay đổi IP dịch vụ',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn thay đổi IP dịch vụ</p>
          <p>
            <b>{dataDetail?.product.planCode}</b> này không?
          </p>
        </div>
      ),
      onOk: () =>
        mutateChangeIP({
          id: dataDetail?.id,
          params: {
            locationId: values.locationId,
            osId: values.osId,
          },
        }),
    });

  const handleCloseConfirm = () =>
    isDirty
      ? confirm.warning({
          title: 'Thoát bản ghi',
          content: (
            <>
              <p>{messageWarning.WARNING_EXIT_1}</p>
              <p>{messageWarning.WARNING_EXIT_2}</p>
            </>
          ),
          onOk: () => close(),
        })
      : close();

  const details = [
    {
      label: 'Địa chỉ IP',
      value: (
        <TextNavigate
          to={`${SERVICE_CLOUD_VPS_URL.detail.path}/${dataDetail?.id}`}
        >
          {dataDetail?.ip}
        </TextNavigate>
      ),
    },
    {
      label: 'Ngày tạo',
      value: dataDetail?.date_created,
    },
    {
      label: 'Ngày hết hạn',
      value: dataDetail?.expires,
    },
    {
      label: 'Vị trí',
      value: dataDetail?.location.locationName,
    },
    {
      label: 'Hệ điều hành',
      value: dataDetail?.os.osName,
    },
    {
      label: 'Ghi chú',
      value: 'Note',
    },
  ];

  const LocationOptions = useMemo(
    () =>
      dataDetail?.product.locations.map((location) => ({
        value: location.location.locationId,
        label: location.location.locationName,
      })),
    [dataDetail?.product.locations],
  );
  const OperatingSystemOptions = useMemo(
    () =>
      dataDetail?.product.operating_systems.map((os) => ({
        value: os.os.osId,
        label: os.os.osName,
      })),
    [dataDetail?.product.operating_systems],
  );

  return (
    <Modal
      title="Đổi IP dịch vụ"
      open={open}
      centered={true}
      okText="Đồng ý"
      cancelText="Hủy"
      onOk={form.handleSubmit(handleChangeIP)}
      onCancel={handleCloseConfirm}
      className="sm:w-[500px]"
      bodyClassName="!py-4"
      confirmLoading={isLoadingDetail || isLoadingChangeIP}
    >
      <Spin spinning={isLoadingDetail || isLoadingChangeIP}>
        {id && isErrorDetail ? (
          <NoData />
        ) : (
          <div className="py-2 flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              {details.map((detail, index) => (
                <div key={index} className="flex">
                  <span className="w-[50%] text-[#6B7280]">{detail.label}</span>
                  <span className="w-[50%]">{detail.value}</span>
                </div>
              ))}
            </div>
            <FormTera form={form} className="flex flex-col gap-2">
              <FormTeraItem label="Vị trí" name="locationId" className="mb-0">
                <Select options={LocationOptions} />
              </FormTeraItem>
              <FormTeraItem
                label="Cài lại hệ điều hành"
                name="osId"
                className="mb-0"
              >
                <Select options={OperatingSystemOptions} />
              </FormTeraItem>
              <FormTeraItem
                label="Xóa dữ liệu"
                name="delete_option"
                className="mb-0"
              >
                <Select options={deleteOptions} />
              </FormTeraItem>
            </FormTera>
          </div>
        )}
      </Spin>
    </Modal>
  );
};

export default ModalChangeIP;

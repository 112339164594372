import ZingIcon from '_common/component/ZingIcon';
import { messageValidate } from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import Input from '_common/dof/Control/Input';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectOperatingSystem from '_common/dof/Select/SelectOperatingSystem';
import { useForm } from 'react-hook-form';
import { Button } from 'tera-dls';

const ReinstallOS = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      osId: '',
      userName: 'root',
      defaultPassword: 'Tự động khởi tạo',
      isBackup: false,
    },
  });

  return (
    <div className="w-full flex-1 bg-white p-4 rounded-[10px] drop-shadow lex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <span className="text-blue-500 font-bold">Cài lại hệ điều hành</span>
        <FormTera form={form} className="flex flex-col gap-2">
          <FormTeraItem
            label="Hệ điều hành cần cài đặt lại"
            name="osId"
            className="mb-0"
            rules={[
              {
                required: messageValidate.emptySelect,
              },
            ]}
          >
            <SelectOperatingSystem
              paramsApi={{
                include_id: form.watch('osId'),
              }}
              allowClear={false}
            />
          </FormTeraItem>
          <FormTeraItem label="Tên đăng nhập" name="userName" className="mb-0">
            <Input disabled />
          </FormTeraItem>
          <FormTeraItem
            label="Mật khẩu mới"
            name="defaultPassword"
            className="mb-0"
          >
            <Input disabled />
          </FormTeraItem>
          <FormTeraItem name="isBackup" className="mb-0">
            <CheckBox labelClassName="text-red-500 font-[400]">
              Tôi đã sao lưu các dữ liệu bên trong VPS và chấp nhận mất toàn bộ
              dữ liệu khi cài đặt lại hệ điều hành.
            </CheckBox>
          </FormTeraItem>
          <Button
            htmlType="submit"
            className="bg-green-500 max-w-max"
            prefix={<ZingIcon.IconSettings />}
          >
            Cài lại hệ điều hành
          </Button>
        </FormTera>
      </div>
    </div>
  );
};

export default ReinstallOS;

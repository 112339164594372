import ZingCloud from '_common/component/Icons/ZingCloud';
import { useStores } from '_common/hooks';
import { usePermission } from '_common/hooks/usePermission';
import useSubMenu from '_common/hooks/useSubMenu';
import classNames from 'classnames';
import { groupBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Bars3BottomRightOutlined, Collapse, Icon, Tooltip } from 'tera-dls';
import { IMenu } from './interface';
import menu from './menu';

export interface InlineMenuProps {
  containerClassName?: string;
  isExpand?: boolean;
  onChangeSize?: (expand: boolean) => void;
}

const InlineMenu: React.FC<InlineMenuProps> = observer(
  ({ isExpand, containerClassName, onChangeSize }): JSX.Element => {
    const [collapseActiveKey, setCollapseActiveKey] = useState<string>();
    const {
      commonStore: { activeMenu },
    } = useStores();
    const { hasPage } = usePermission();
    const location = useLocation();
    const navigate = useNavigate();
    const listMenu = useSubMenu();

    const handleClick = (path: string): void => {
      if (path === window.location.pathname) return;
      navigate(path);
    };

    const getActiveKeyInURL = (): string => {
      const splitted = location?.pathname
        .substring(1)
        .replace(`${activeMenu}/`, ``)
        .split('/');

      if (splitted.length === 1) return splitted[0];

      return splitted[0] + `${splitted[1] ? `-${splitted[1]}` : ''}`;
    };

    useEffect(
      () => setCollapseActiveKey(getActiveKeyInURL()),
      [location?.pathname, activeMenu],
    );

    const handleChangeSizeMenu = () => {
      onChangeSize(!isExpand);
    };

    const menuClasses = classNames(
      `transition-all h-full py-5 bg-blue-800 overflow-hidden`,
      containerClassName,
      {
        'w-[225px] rounded-se-[20px] rounded-ee-[20px] pr-2.5': isExpand,
        'w-[50px] px-0': !isExpand,
      },
    );

    const isActiveKey = (key: string): boolean => collapseActiveKey === key;

    const getActiveClasses = (activeKeys: any = []): string => {
      const isActive = activeKeys.some((key) =>
        location?.pathname.includes(key),
      );
      return isActive ? 'bg-white/25 ' : '';
    };

    const groupListMenu = groupBy(listMenu, 'parentGroupKey');

    const listCollapse = useCallback(
      (arrMenu) =>
        arrMenu
          ?.filter((item) => hasPage(item?.permission))
          ?.map((item: IMenu) => {
            return {
              key: item.key,
              onClick: () => item.path && handleClick(item.path),
              label: (
                <Tooltip
                  className={isExpand && 'hidden'}
                  title={item.title}
                  placement="right"
                >
                  <div
                    className={classNames(
                      'flex items-center gap-2.5 font-normal text-gray-200',
                      { '!gap-1 py-[8px] justify-center w-full': !isExpand },
                    )}
                  >
                    {isExpand && (
                      <div
                        className={`w-[3px] h-[15px] rounded-r-[2px] ${
                          !isActiveKey(item.key) ? '' : 'bg-[#00AFEF]'
                        }`}
                      />
                    )}
                    {item.icon && (
                      <Icon
                        type={item.icon}
                        className={`${isExpand ? 'size-4' : 'size-5'} shrink-0`}
                      />
                    )}
                    {item?.iconNode && (
                      <i className="text-white menu-icon">{item.iconNode}</i>
                    )}
                    <span
                      className={classNames('text-wrap', { hidden: !isExpand })}
                    >
                      {item.title}
                    </span>
                  </div>
                </Tooltip>
              ),
            };
          }),
      [listMenu, collapseActiveKey, getActiveClasses],
    );

    const renderTitle = (title) => {
      if (isExpand) return title;
      return title.slice(0, 1);
    };

    const elementRef = useRef(null);

    const renderHeading = () => {
      const data = menu.groupMenu.find((item) => item?.key === activeMenu);
      return data?.title || 'ZingCloud';
    };

    return (
      <div className="hidden xmd:block fixed top-0 left-0 transition-all h-screen shrink-0 bg-gradient-to-b from-white to-[#F3F3F9]">
        <div className={menuClasses}>
          <div className="flex flex-col gap-y-5 items-center h-full">
            <div
              className={classNames(
                'flex items-center w-full justify-between',
                {
                  'flex-col': !isExpand,
                  'gap-y-5': !isExpand,
                },
              )}
            >
              <div
                className={classNames('flex items-center', {
                  'gap-x-4 pl-2.5': isExpand,
                })}
              >
                <ZingCloud />
                <h1
                  className={classNames('text-[21px] text-white font-medium', {
                    hidden: !isExpand,
                  })}
                >
                  {renderHeading()}
                </h1>
              </div>
              <Bars3BottomRightOutlined
                className="size-5 text-white cursor-pointer"
                onClick={handleChangeSizeMenu}
              />
            </div>
            <div
              ref={elementRef}
              className={classNames(
                'flex-1 flex flex-col gap-y-5 overflow-auto w-full scrollbar-none',
                {
                  'shadow-md inset-y-0 bottom-0':
                    elementRef.current?.scrollHeight >
                    elementRef.current?.clientHeight,
                },
              )}
            >
              {Object.entries(groupListMenu)
                .filter(([, value]) => {
                  return value.some((item: any) => hasPage(item.permission));
                })
                .map(([key, value]) => {
                  const name = menu?.parentGroup?.find(
                    (i) => i.key === key,
                  )?.title;

                  return (
                    <div
                      key={key}
                      className={classNames('flex flex-col gap-y-[5px]', {
                        'items-center': !isExpand,
                      })}
                    >
                      {name && (
                        <Tooltip
                          className={isExpand && 'hidden'}
                          title={name}
                          placement="right"
                        >
                          <h3
                            className={classNames(
                              'ml-2.5 uppercase text-gray-200 font-medium transition-all leading-6',
                              {
                                '!ml-0 !font-bold size-5 rounded-full bg-white/25 flex items-center justify-center':
                                  !isExpand,
                              },
                            )}
                          >
                            {renderTitle(name)}
                          </h3>
                        </Tooltip>
                      )}
                      <Collapse
                        accordion
                        activeKey={collapseActiveKey}
                        containerClassName="flex flex-col gap-2.5 w-full"
                        headingClassName={classNames(
                          'p-2.5 bg-blue100 border-none !rounded-e-full text-gray-200 focus:ring-0 hover:bg-white/25',
                          { 'p-0 !rounded-none': !isExpand },
                        )}
                        contentClassName={classNames(
                          'border-none p-0 !text-red-500',
                        )}
                        activeClassName="bg-white/25 text-gray-200"
                        onChange={(key: any) => {
                          if (key?.length) setCollapseActiveKey(key[0]);
                        }}
                        items={listCollapse(value)}
                      />
                    </div>
                  );
                })}
            </div>
            {isExpand && (
              <p className="text-gray-200">@Copyright Zing Cloud ver 1.0</p>
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default InlineMenu;

import { useMutation, useQueryClient } from '@tanstack/react-query';
import CardDetail from '_common/component/CardDetail';
import Text from '_common/component/Text';
import CheckBox from '_common/dof/Control/CheckBox';
import CheckBoxGroup from '_common/dof/Control/CheckboxGroup';
import Input from '_common/dof/Control/Input';
import RadioGroup from '_common/dof/Control/RadioGroup';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectPaymentMethod from '_common/dof/Select/SelectPaymentMethod';
import _ from 'lodash';
import UserAccountAPI from 'pages/UserManagement/Account/apis';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, Row, Spin, notification } from 'tera-dls';
import {
  CreditOptions,
  EmailInformationOptions,
  MergeInvoiceOptions,
  NameserverOptions,
  RecordPerPageOptions,
  TimeZoneOptions,
} from '../constants';

interface ISetupSecurityProps {
  dataDetail: any;
  isAdmin?: boolean;
}

const SetupSecurity = ({ dataDetail, isAdmin }: ISetupSecurityProps) => {
  const applyMethodForAllService = useMemo(
    () =>
      dataDetail.setting?.apply_method_for_all_service === 1 ? true : false,
    [dataDetail.setting],
  );
  const emailNotification = useMemo(
    () =>
      dataDetail.setting?.email_notification
        ? dataDetail.setting?.email_notification.split(',')
        : [],
    [dataDetail.setting],
  );

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      record_per_page: dataDetail?.setting?.record_per_page,
      time_zone: dataDetail?.setting?.time_zone,
      payment_method_id: dataDetail?.setting?.payment_method_id,
      apply_method_for_all_service: applyMethodForAllService,
      auto_credit: dataDetail?.setting?.auto_credit,
      merge_invoice: dataDetail?.setting?.merge_invoice,
      email_notification: emailNotification,
      nameserver: dataDetail?.setting?.nameserver,
      ns1: dataDetail?.setting?.ns1,
      ns2: dataDetail?.setting?.ns2,
      ns3: dataDetail?.setting?.ns3,
      ns4: dataDetail?.setting?.ns4,
    },
  });
  const queryClient = useQueryClient();

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(
    (params) => UserAccountAPI.updateSetting({ id: dataDetail?.id, params }),
    {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-profile']);
          queryClient.invalidateQueries(['get-user-account-detail']);
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleSubmitForm = (values: any) => {
    if (isLoadingUpdate) return;

    const data = {
      ...values,
      apply_method_for_all_service: values.apply_method_for_all_service ? 1 : 0,
      email_notification: values.email_notification.join(','),
    };

    mutateUpdate({ ...data });
  };

  return (
    <Spin spinning={isLoadingUpdate}>
      <Col className="w-full flex flex-col items-start gap-4">
        <CardDetail className="w-full">
          <Text className="text-gray-500">
            Hệ thống tiếp thị liên kết toàn diện
          </Text>
          <FormTera
            form={form}
            className="flex flex-col divide-y-[2px] divide-dotted"
          >
            <CardDetail
              className="text-sm !shadow-none pl-0"
              title="Thông tin chung"
            >
              <Row className="grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                <FormTeraItem
                  label="Số dòng hiển thị mỗi trang"
                  name="record_per_page"
                  className="mb-0"
                >
                  <Select options={RecordPerPageOptions} />
                </FormTeraItem>
                <FormTeraItem label="Múi giờ" name="time_zone" className="mb-0">
                  <Select options={TimeZoneOptions} />
                </FormTeraItem>
              </Row>
            </CardDetail>
            {!isAdmin && (
              <CardDetail
                title="Thanh toán"
                className="text-sm !shadow-none pl-0 flex flex-col gap-2"
              >
                <Col className="border-l-[2px] border-blue-500 px-2.5 flex flex-col gap-2">
                  <FormTeraItem
                    label="Phương thức thanh toán mặc định"
                    name="payment_method_id"
                    className="mb-0"
                  >
                    <SelectPaymentMethod
                      paramsApi={{
                        account_id: dataDetail?.id,
                        include_id: form.watch('payment_method_id'),
                      }}
                      allowClear={false}
                    />
                  </FormTeraItem>
                  <FormTeraItem
                    name="apply_method_for_all_service"
                    className="mb-0"
                  >
                    <CheckBox labelClassName="font-normal text-[14px]">
                      Áp dụng cho tất cả các dịch vụ
                    </CheckBox>
                  </FormTeraItem>
                </Col>
                <FormTeraItem
                  label="Tự động sử dụng Credit"
                  name="auto_credit"
                  className="mb-0"
                >
                  <Select allowClear={false} options={CreditOptions} />
                </FormTeraItem>
                <FormTeraItem
                  label="Hợp nhất hóa đơn"
                  name="merge_invoice"
                  className="mb-0"
                >
                  <RadioGroup listOptions={MergeInvoiceOptions} />
                </FormTeraItem>
              </CardDetail>
            )}
            <CardDetail className="text-sm !shadow-none pl-0" title="Thông báo">
              <FormTeraItem
                label="Thông báo email"
                name="email_notification"
                className="mb-0"
              >
                <CheckBoxGroup listOptions={EmailInformationOptions} />
              </FormTeraItem>
            </CardDetail>
            {!isAdmin && (
              <CardDetail
                title="Domains"
                className="text-sm !shadow-none pl-0 flex flex-col gap-2"
              >
                <FormTeraItem
                  label="Nameservers"
                  name="nameserver"
                  className="mb-0"
                >
                  <RadioGroup listOptions={NameserverOptions} />
                </FormTeraItem>
                {_.isEqual(
                  form.watch('nameserver'),
                  NameserverOptions[1].value,
                ) && (
                  <Col className="w-full sm:w-[60%] xmd:w-[50%] flex flex-col gap-2">
                    <FormTeraItem label="NS1" name="ns1" className="mb-0">
                      <Input placeholder="Vui lòng nhập" />
                    </FormTeraItem>
                    <FormTeraItem label="NS2" name="ns2" className="mb-0">
                      <Input placeholder="Vui lòng nhập" />
                    </FormTeraItem>
                    <FormTeraItem label="NS3" name="ns3" className="mb-0">
                      <Input placeholder="Vui lòng nhập" />
                    </FormTeraItem>
                    <FormTeraItem label="NS4" name="ns4" className="mb-0">
                      <Input placeholder="Vui lòng nhập" />
                    </FormTeraItem>
                  </Col>
                )}
              </CardDetail>
            )}
          </FormTera>
        </CardDetail>
        <Button
          className="font-light text-[12px] bg-blue-500"
          onClick={form.handleSubmit(handleSubmitForm)}
        >
          Lưu
        </Button>
      </Col>
    </Spin>
  );
};

export default SetupSecurity;

import React, { useState } from 'react';
import Content from './containers/Content';
export const StatisticCashExpenseParamContext = React.createContext(null);

const StatisticCashExpense = () => {
  const [contextParams, setContextParams] = useState<any>();
  return (
    <StatisticCashExpenseParamContext.Provider
      value={{ contextParams, setContextParams }}
    >
      <Content chartClassName="w-full !aspect-[2/1]" />
    </StatisticCashExpenseParamContext.Provider>
  );
};

export default StatisticCashExpense;

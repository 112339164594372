import { useMutation, useQueryClient } from '@tanstack/react-query';
import { messageValidate, messageWarning } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import SecurityAPI from 'states/api/SecurityAPI';
import { Modal, notification } from 'tera-dls';

interface IModalModalCreateSSHKeyProps {
  open: boolean;
  close: () => void;
  id?: string | number;
}

const ModalCreateSSHKey = ({
  open,
  close,
  id,
}: IModalModalCreateSSHKeyProps): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      ssh_key_name: '',
      ssh_key_key: '',
    },
  });
  const { isDirty } = form.formState;
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateCreateUpdate, isLoading: isLoadingCreateUpdate } =
    useMutation((params) => SecurityAPI.createSecuritySSHKey({ params }), {
      onSuccess: (res) => {
        if (_.isEqual(res?.code, 200)) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-security-ssh-key-list']);
          close();
        }
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    });

  const handleSubmitForm = (values: any) => {
    if (isLoadingCreateUpdate) return;
    mutateCreateUpdate({
      ...values,
      account_id: id,
    });
  };

  return (
    <Modal
      title="Thêm mới SSH Key"
      open={open}
      centered={true}
      destroyOnClose
      okText="Đồng ý"
      cancelText="Hủy"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={() =>
        isDirty
          ? confirm.warning({
              title: 'Thoát bản ghi',
              content: (
                <>
                  <p>{messageWarning.WARNING_EXIT_1}</p>
                  <p>{messageWarning.WARNING_EXIT_2}</p>
                </>
              ),
              onOk: close,
            })
          : close()
      }
      className="sm:w-[500px]"
      bodyClassName="!py-4"
      confirmLoading={isLoadingCreateUpdate}
    >
      <FormTera form={form} className="flex flex-col gap-2">
        <FormTeraItem
          label="Tên SSH Key"
          name="ssh_key_name"
          className="mb-0"
          rules={[
            {
              required: messageValidate.emptyText,
            },
          ]}
        >
          <Input />
        </FormTeraItem>
        <FormTeraItem
          label="SSH Key in OpenSSH format"
          name="ssh_key_key"
          className="mb-0"
        >
          <TextArea rows={10} />
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
};

export default ModalCreateSSHKey;

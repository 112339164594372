import { useQuery } from '@tanstack/react-query';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import moment from 'moment';
import {
  CASH_RECEIPT_TYPE,
  EXPENSE_VOUCHER_TYPE,
} from 'pages/Finance/InvoiceManagement/_common/constants';
import { useContext, useEffect, useMemo, useState } from 'react';
import startUpIdea from 'styles/images/start_up_idea.png';
import customTwMerge from 'tailwind-merge.config';
import { ChartBarProps, ChartPie, Spin, useDetectDevice } from 'tera-dls';
import { StatisticCashExpenseByPaymentMethodParamContext } from '..';
import StatisticApi from '../../../api';
import Header from '../../Header';
import ModalChart from './Modal';
import SettingModal from './Setting';
import { PAYMENT_METHOD_TYPE } from 'pages/Finance/constants';

export type OpportunityValueStatisticByStage = 'small' | 'large';

interface IProps {
  type?: OpportunityValueStatisticByStage;
  onClose?: () => void;
  chartOptions?: ChartBarProps['options'];
  chartClassName?: string;
  defaultLengthShowTick?: number;
  defaultLengthScroll?: number;
  labelMaxLength?: number;
}

const Content = (props: IProps) => {
  const { type = 'small', onClose, chartClassName } = props;
  const [openModal, setOpenModal] = useState<boolean>();
  const [openSetting, setOpenSetting] = useState<boolean>(false);
  const { contextParams } = useContext(
    StatisticCashExpenseByPaymentMethodParamContext,
  );
  const { widthScreen } = useDetectDevice();

  const currentWeek = [
    moment().startOf('week').format(DATE_BACKEND_FORMAT),
    moment().endOf('week').format(DATE_BACKEND_FORMAT),
  ];

  const [params, setParams] = useState<any>({
    receipt_type: Object.keys(CASH_RECEIPT_TYPE),
    expense_type: Object.keys(EXPENSE_VOUCHER_TYPE),
    receipt_date: currentWeek,
    expense_date: currentWeek,
    time: 'currentWeek',
  });

  const {
    data: response,
    refetch,
    isRefetching,
  } = useQuery(
    ['get-statistic-by-method', contextParams],
    () =>
      StatisticApi.getChart({
        params: {
          chart_type: 'byPaymentMethod',
          investor_id: contextParams?.investor_id,
          receipt_transaction_type:
            contextParams?.receipt_transaction_type?.join(','),
          payment_transaction_type:
            contextParams?.payment_transaction_type?.join(','),
          date_from: contextParams?.date[0],
          date_to: contextParams?.date[1],
          payment_method: contextParams?.payment_method?.join(','),
          show_by: contextParams?.show_by,
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, [params]);

  const expenseVoucherOptions: any = {
    plugins: {
      datalabels: {
        formatter: function (_, values) {
          const total = values?.dataset?.data?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0,
          );
          const index = values?.dataIndex;
          const value = (values?.dataset?.data[index] / total) * 100;
          if (value === 0) return null;
          const percent = value.toFixed(1);
          return value === 0 ? '' : `${percent}%`;
        },
        color: 'white',
      },
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      title: {
        display: true,
        text: 'Chi',
        position: 'top',
        padding: 20,
      },
      maintainAspectRatio: false,
    },
  };

  const cashReceiptOptions: any = {
    plugins: {
      datalabels: {
        formatter: function (_, values) {
          const total = values?.dataset?.data?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0,
          );
          const index = values?.dataIndex;
          const value = (values?.dataset?.data[index] / total) * 100;
          if (value === 0) return null;
          const percent = value.toFixed(1);
          return value === 0 ? '' : `${percent}%`;
        },
        color: 'white',
      },
      legend: {
        position: 'bottom',
        labels: {
          padding: 20,
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      title: {
        display: true,
        text: 'Thu',
        position: 'top',
        padding: 20,
      },

      maintainAspectRatio: false,
    },
  };

  const dataSource = useMemo(() => {
    const labelsCashReceipt =
      response?.group_by?.payment &&
      Object.keys(response?.group_by?.receipt)?.map(
        (key) => PAYMENT_METHOD_TYPE[key],
      );
    const labelsExpenseVoucher =
      response?.group_by?.payment &&
      Object.keys(response?.group_by?.payment)?.map(
        (key) => PAYMENT_METHOD_TYPE[key],
      );
    const dataLabelsExpenseVoucher: any = {
      data:
        response?.group_by?.payment &&
        Object.values(response?.group_by?.payment).map(
          (item: any) => item?.total_amount,
        ),
      backgroundColor: ['#31C48D', '#F3722C'],
      name: 'expense_voucher',
      borderWidth: 0,
    };
    const dataLabelsCashReceipt: any = {
      data:
        response?.group_by?.receipt &&
        Object.values(response?.group_by?.receipt).map(
          (item: any) => item?.total_amount,
        ),
      backgroundColor: ['#31C48D', '#F3722C'],
      name: 'receipt',
      borderWidth: 0,
    };

    return {
      receipt: {
        labels: labelsCashReceipt,
        datasets: [dataLabelsCashReceipt],
      },
      expenseVoucher: {
        labels: labelsExpenseVoucher,
        datasets: [dataLabelsExpenseVoucher],
      },
    };
  }, [response]);

  const isSmallType = type === 'small';
  const checkData = (data) =>
    data?.datasets?.[0]?.data?.some((value) => value !== 0);

  return (
    <>
      <div className="rounded-[5px] border-[1px] bg-white h-full overflow-hidden flex flex-col">
        <Header
          title={'Thu chi theo phương thức thanh toán'}
          onClickRefresh={() => refetch()}
          onClickConfig={() => setOpenSetting(true)}
          onZoomOut={() => setOpenModal(true)}
          onZoomIn={() => onClose()}
          isSmallType={isSmallType}
        />
        <div className="flex w-full justify-between p-10 flex-1 gap-x-2.5">
          <Spin spinning={isRefetching}>
            <div
              className={customTwMerge(
                'flex w-[40%] items-center',
                chartClassName,
              )}
            >
              {!isRefetching && !checkData(dataSource?.receipt) ? (
                <div className="flex flex-col">
                  <p className="text-gray-500 font-medium text-center w-full ">
                    Thu
                  </p>
                  <img src={startUpIdea} className="mb-[60px]" />
                  <p className="text-gray-400 font-normal text-center w-full ">
                    Chưa có dữ liệu
                  </p>
                </div>
              ) : (
                <ChartPie
                  key={widthScreen}
                  data={dataSource?.receipt}
                  plugins={['data-labels']}
                  options={cashReceiptOptions}
                />
              )}
            </div>
          </Spin>
          <Spin spinning={isRefetching}>
            <div
              className={customTwMerge(
                'flex w-[40%] items-center',
                chartClassName,
              )}
            >
              {!isRefetching && !checkData(dataSource?.expenseVoucher) ? (
                <div className="flex flex-col">
                  <p className="text-gray-500 font-medium text-center w-full ">
                    Chi
                  </p>
                  <img src={startUpIdea} className="mb-[60px]" />
                  <p className="text-gray-400 font-normal text-center w-full ">
                    Chưa có dữ liệu
                  </p>
                </div>
              ) : (
                <ChartPie
                  key={widthScreen}
                  data={dataSource?.expenseVoucher}
                  plugins={['data-labels']}
                  options={expenseVoucherOptions}
                />
              )}
            </div>
          </Spin>
        </div>
      </div>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
      {openSetting && (
        <SettingModal
          open={openSetting}
          onClose={() => setOpenSetting(false)}
          onSubmit={setParams}
          defaultValues={params}
        />
      )}
    </>
  );
};

export default Content;
